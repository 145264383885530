import { render, staticRenderFns } from "./index.vue?vue&type=template&id=187f1fa0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Makes: require('/home/d/didisher2/vzletka-cars.ru/www/components/makes/makes.vue').default,FilterList: require('/home/d/didisher2/vzletka-cars.ru/www/components/filter/filter-list.vue').default,CatalogListIndex: require('/home/d/didisher2/vzletka-cars.ru/www/components/catalog/catalog-list/catalog-list-index.vue').default,Services: require('/home/d/didisher2/vzletka-cars.ru/www/components/services/services.vue').default,FeaturedBanks: require('/home/d/didisher2/vzletka-cars.ru/www/components/featured/featured-banks.vue').default,Contacts: require('/home/d/didisher2/vzletka-cars.ru/www/components/contacts/contacts.vue').default,SeoTextHome: require('/home/d/didisher2/vzletka-cars.ru/www/components/seo-text/seo-text-home.vue').default})
